import React from "react";
import { graphql } from "gatsby";
import { useTranslation, Link } from "gatsby-plugin-react-i18next";

import Layout from "../../components/Layout";
import { urls, extendUrlWithSourceVersion } from "../../constants/urls";
import MetaComponents from "../../components/seo/MetaComponents";
import ArticleStructuredData from "../../components/seo/ArticleStructuredData";
import Article from "../../components/Article";
import ArticleHeader from "../../components/ArticleHeader";
import MortgageCalculator from "../../components/MortgageCalculator";
import BreadcrumbList from "../../components/seo/BreadcrumbList";

const Immobilienbewertung = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <MetaComponents title={t("page.meta.title")} description={t("page.meta.description")} />
            <ArticleHeader h1Title="Immobilienbewertung – Wie funktioniert das?" showCalc={false} />
            <Article>
                <p>
                    Auf der Suche nach deinem neuen Zuhause stößt du immer wieder auf Immobilienpreise, die dir viel zu
                    hoch erscheinen, ohne dass du weißt, wie sie überhaupt zustandekommen? Oder bist du selbst gerade
                    dabei, ein Objekt zu verkaufen, jedoch nicht sicher, wie viel du dafür verlangen kannst? Sei
                    unbesorgt – in diesem Artikel erklären wir dir die Basics der Immobilienbewertung.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Was ist eine Immobilienbewertung?</h2>
                <p>
                    Unter Immobilienbewertung versteht man Rechen- und Vergleichsverfahren zur Ermittlung eines
                    realistischen Kaufpreises. Dabei können unterschiedliche Methoden zur Anwendung kommen. So lässt
                    sich beispielsweise der Verkehrswert einer Immobilie als Berechnungsgrundlage verwenden: In diesem
                    Fall nimmt man an, das betreffende Objekt würde vermietet, und errechnet so einen Verkehrswert, von
                    dem auf den Verkaufswert geschlossen werden kann. Eine einfachere Alternative stellt der
                    Vergleichswert da. Hier werden die Preise ähnlicher Objekte in der Umgebung als Berechnungsgrundlage
                    herangezogen.
                </p>
                <hr />

                <h2>Nach welchen Kriterien werden Immobilien bewertet?</h2>
                <p>Zentrale Kriterien, die zur Immobilienbewertung herangezogen werden, sind:</p>
                <ul>
                    <li>
                        <strong>Mietpreise:</strong> Diese stellen eine wichtige Grundlage der Immobilienbewertung dar.
                        Je mehr Miete für das betreffende Objekt verlangt werden könnte, desto höher kann auch der
                        Kaufpreis veranschlagt werden.
                    </li>
                    <li>
                        <strong>Lage:</strong> Beeinflusst wird die potenzielle Miete natürlich von der Lage der
                        jeweilige Immobilie. Insbesondere die Verkehrsanbindung, die Nähe zu Bildungseinrichtungen sowie
                        zu Einkaufs- und Erholungsmöglichkeiten führen zu höheren Bewertungen.
                    </li>
                    <li>
                        <strong>Zustand, Qualität und Ausstattung:</strong> Die Bausubstanz sowie der Zustand von Böden,
                        Türen, Fenstern und sanitären Einrichtungen entscheiden über die Qualität einer Immobilie, die
                        ebenfalls ein zentrales Kriterium der Immobilienbewertung darstellt.
                    </li>
                    <li>
                        <strong>Art der Finanzierung:</strong> Im Unterschied zu frei finanzierten Wohnungen, deren
                        Preis prinzipiell frei bestimmt werden kann, sich also vor allem an der je aktuellen
                        Marktsituation orientiert, wird der Preis geförderter Objekte durch eine Obergrenze reguliert.
                    </li>
                    <li>
                        <strong>Art des Mietzinses:</strong> Wurde der Bau des Gebäudes vor dem 9. Mai 1975 bewilligt,
                        so kommt seit 1994 der Richtwertzins zur Anwendung. Dieser sieht eine Unterteilung von Wohnungen
                        in die Kategorien A bis D vor – in Abhängigkeit von Qualität und Zustand.
                    </li>
                </ul>
                <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                    Kreditvergleich anfordern
                </a>
                <hr />

                <h2>Wann sollte eine Immobilienbewertung vorgenommen werden?</h2>
                <p>In folgenden Fällen ist es ratsam, eine Immobilienbewertung in Erwägung zu ziehen:</p>
                <ul>
                    <li>
                        <strong>geplanter Verkauf:</strong> Wenn du den Verkauf einer Immobilie planst, ist es ratsam,
                        einen Sachverständigen zu beauftragen, der mit dem lokalen Markt vertraut ist.
                    </li>
                    <li>
                        <strong>Erbschaft:</strong> Gerade wenn es um die Verteilung von Nachlässen auf mehrere
                        Erb:innen geht, ist es notwendig, über den Betrag der einzelnen Vermögenswerte genau Bescheid zu
                        wissen. Dazu gehört auch die Bewertung von Immobilien, die von einem unabhängigen
                        Sachverständigen durchgeführt wird.
                    </li>
                    <li>
                        <strong>Kauf:</strong> Leider kann es vorkommen, dass der Preis eines Objektes, an dem du
                        interessiert bist, unangemessen erscheint. Auch in diesem Fall ist es möglich, eine:n
                        unabhängige Sachverständige:n mit der Bewertung zu betrauen. Damit verschaffst du dir eine
                        günstige Verhandlungsbasis.
                    </li>
                    <li>
                        <strong>Beantragung eines Hypothekarkredits:</strong> Auch wenn du einen Hypothekarkredit
                        aufnimmst, nimmt die Bank ein Bewertung vor. Weil von Bank zu Bank allerdings auf
                        unterschiedliche Algorithmen zurückgegriffen wird, weichen auch die daraus resultierenden
                        Bewertung teilweise erheblich voneinander ab.
                    </li>
                </ul>
                <hr />

                <h2>Immobilienbewertung online – wie sinnvoll ist das?</h2>
                <p>
                    So, wie{" "}
                    <Link to="/dienstleistungen/kreditrechner/" target="_blank" rel="noreferrer noopener">
                        Kreditrechner
                    </Link>{" "}
                    ein hilfreiches Tool zur Orientierung über Finanzierungskosten darstellen, können dir auch online
                    verfügbare Bewertungsverfahren dabei helfen, eine erste Vorstellung vom ungefähren Wert einer
                    Immobilie zu bekommen. Die Betonung liegt hier allerdings auf ungefähr: Da die Daten, auf die bei
                    online-Bewertungen zurückgegriffen wird, meist einige Monate alt sind und auch die konkrete
                    Beschaffenheit der in Frage stehenden Immobilie nur in gewissen Grenzen berücksichtigt werden kann,
                    führt die Immobilienbewertung durch einen Sachverständigen meist zu belastbareren Ergebnissen.
                    Solltest du vor dem Kauf einer Immobilie stehen, dir aber nicht sicher sein, ob der geforderte Preis
                    tatsächlich adäquat ist, raten wir dir also, eine:e unabhängige Expert:in zu beauftragen. Was aber
                    in jedem Fall online funktioniert: Die persönlichen Beratungsgespräche von miracl.
                </p>
            </Article>
            <MortgageCalculator noTopMargin />

            <BreadcrumbList page={"immobilienbewertung"}></BreadcrumbList>
            <ArticleStructuredData
                page={"immobilienbewertung"}
                heading={"Immobilienbewertung – Wie funktioniert das?"}
            />
        </Layout>
    );
};

export default Immobilienbewertung;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["header", "footer", "mortgageCalc", "page.artikel.immobilienbewertung"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
